import styled from "styled-components"
import { Form, FormControl } from "react-bootstrap"
import { InputGroup } from "react-bootstrap"

export const LoginWrapper = styled(Form)`
  //text-align: center;
  background-color: #008888;
  color: white;
`;

export const InputGroupWrp = styled(InputGroup)`
  margin-top: 20px;
`;

export const SubmitButton = styled(FormControl)`
    background-color: red;
    color: white;
    font-weight: bold;
    :hover {
      background-color: white;
      color: red;
    }
`;

export const LogoImg = styled.img`
  padding-top: 20px;
  width: 50%;
`;
export const MailImg = styled.img`
  padding-top: 20px;
  height: 30px;
`;
export const MobileImg = styled.img`
  height: 40px;
`;
export const Error = styled.div`
  color: red;
`;
