import React from "react"
import { Form, Container, InputGroup, FormControl, FormGroup, Col } from "react-bootstrap"
import { LogoImg, MailImg, MobileImg, SectaApplicationFormWrapper, SubmitButton, InputGroupWrp, Error } from "./styles/SectaApplicationFormStyles"
import axios from 'axios'
// import { connect } from 'react-redux'
import Logo from "../images/SECTA-logo-wave.png"
import Mail from "../images/mail.png"
import Mobile from "../images/Mobile.png"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
// import { SetToken } from '../functions/getToken'
import { getToken } from "../services/auth"

const SectaApplicationForm = () => {
  const { handleSubmit, register, errors } = useForm()
  // if (props.token === ''){
  //   SetToken()
  // }

  const onSubmit = values => {
      if (values.password !== values.passwordConfirm) {
        errors["passwordConfirm"] = "Passwords don't match.";
        alert('Passwords do not match');
        return;
      }
      axios({
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authentication': 'Bearer ' + getToken()
        },
        url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member`,
        data: {
          status: `publish`,
          fields: {
            member_email: `${values.email}`,
            member_password: `${values.password}`,
            member_name: `${values.name}`,
            member_business_name: `${values.business_name}`,
            member_address: `${values.addr}`,
            member_town: `${values.town}`,
            member_phone: `${values.phone}`,
            member_mobile: `${values.mobile}`,
            member_website: `${values.website}`,
            member_alt_address: `${values.altAddr}`,
            member_alt_town: `${values.altTown}`,
            member_alt_phone: `${values.altPhone}`,
            member_alt_mobile: `${values.altMobile}`,
            member_alt_email: `${values.altEmail}`
          }
        }
      })
        // .then(res => console.log('Success'))
        // .catch(err => console.log('Failed: ', err))
      alert(`SECTA Member ${values.name} has been successfully created`)
      navigate('/')
    // })
  }
  return (
    <SectaApplicationFormWrapper onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-email">Email</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="email"
                ref={register({
                  required: "An email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.email && errors.email.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-password">Password</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="password"
                type="password"
                ref={register({ required: "Password is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.password && errors.password.message}&nbsp;</Error>
          </Col>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-passwordConfirm">Confirm Password</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="passwordConfirm"
                type="password"
                ref={register({ required: "Password confirmation is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.passwordConfirm && errors.passwordConfirm.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-name">Your Name(s)</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="name"
                ref={register({ required: "Name is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.name && errors.name.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-business_name">Business Name</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl name="business_name" ref={register()} />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-addr">Address</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="addr" ref={register()}
                // ref={register({ required: "Address line is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.addr && errors.addr.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altTown">Town</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl name="town" ref={register()} />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-phone">Phone</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="phone"
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid phone number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.phone && errors.phone.message}&nbsp;</Error>
          </Col>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-mobile">Mobile</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="mobile"
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid mobile number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.mobile && errors.mobile.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-website">Website</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="website"
                ref={register({
                  pattern: {
                    value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/i,
                    message: "Invalid website",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.website && errors.website.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <p>The above details will be entered on the website for members of the public to see. If you have an alternative address, phone/mobile number or email address for the SECTA administrator and news service to contact you, please fill in the details below.</p>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altAddr">Address</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl name="altAddr" ref={register()} />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altTown">Town</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl name="altTown" ref={register()} />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altPhone">Phone</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altPhone"
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid phone number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.altPhone && errors.altPhone.message}&nbsp;</Error>
          </Col>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altMobile">Mobile</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altMobile"
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid phone number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.altMobile && errors.altMobile.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altEmail">Email</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altEmail"
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.altEmail && errors.altEmail.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs={10}></Col>
          <Col>
            <FormGroup>
              <SubmitButton name="submit" id="submit" type="submit" value="Submit" variant="filled" />
            </FormGroup>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        <p>I agree to comply with all statutory obligations, and to abide by
          the <a href="/rules-and-codes-of-practise">Rules and Codes of Practice</a> of the South East Cornwall Tourism Association.</p>
        <Form.Row>
          <Col xs={8}>
            <LogoImg src={Logo} alt="logo"/>
          </Col>
          <Col>
            <p>Member contact: Trish Brock-Morgan<br/>
              <MailImg src={Mail} alt="mail" /> trish@trecarnehouse.co.uk<br/>
              <MobileImg src={Mobile} alt="mobile" /> 07950 262682</p>
          </Col>
        </Form.Row>
      </Container>
    </SectaApplicationFormWrapper>
  )
}
// const mapStateToProps = ({ token }) => {
//   return { token }
// }
// export default connect(mapStateToProps)(SectaApplicationForm)
export default SectaApplicationForm
