import React from 'react';
import { Link } from "gatsby"

const Members = () => {
    return (
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <button className="btn btn-primary"><Link to="https://members.secta.org/login/login.php">Members Login</Link></button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Members;
