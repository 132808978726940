import React, { Component } from "react"
import PropTypes from "prop-types"
import DateButton from "./post/DateButton"
import { navigate } from "gatsby"
import { H2Button } from "./styles/NewsHeadingStyles"
import { isLoggedIn } from "../services/auth"

class EventHeading extends Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
  }

  gotoPost(slug){
    if(!isLoggedIn()) {
      navigate("/login")
    } else {
      navigate('/eventItem/', { state: { slug } })
    }
  }

  render() {
    const { title, excerpt, slug, acf } = this.props.event
    const dt = acf.event_date
    const dtf = dt.replace(' ', 'T')
    const eventDate = new Date(dtf)
    const today = new Date()

    if (eventDate > today){
    return (
      <div>
        <div className="row">
          <div className="col-sm-2">
            <DateButton date={acf.event_date} slug={slug} />
          </div>
          <div className="col-sm-10">
            <H2Button onClick={this.gotoPost.bind(this, slug)}>{title.rendered}</H2Button>
            <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
            {/*<EventButton slug={slug} />*/}
            <br />
            <hr />
          </div>
        </div>
      </div>
    )} else {
      return null
    }
  }
}

export default EventHeading
