import styled from "styled-components"

export const YourTeamWrapper = styled.div`
  text-align: center;
  background-color: #444444;
  color: white;
  img {
    max-height: 200px;
    border-radius: 50%;
    
    @media (max-width: 768px) {
      max-height: 100px;
    }
  }
`;
