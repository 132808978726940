import React, { Component } from "react"
import axios from 'axios'
import EventHeading from "./EventHeading"
import { EventClassWrapper } from "./index/styles/EventClassWrapper"

class EventsClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      events: []
    }
  }
  componentDidMount() {
    axios({
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/events?orderby=event_date&order=asc`,
    })
      .then(res => this.setState({
        events: res.data,
        isLoaded: true
      }))
      .catch(err => console.log(err))
    // })
  }

  render() {
    const {events} = this.state;
    return (
      <EventClassWrapper>
        <p className='warning'>Due to the ongoing pandemic, all future events will be restricted to online meetings only.</p>
        {
          events.map(event =>
            <EventHeading key={event.id} event={event} />
            )
        }
        </EventClassWrapper>
    )
  }
}

export default EventsClass
