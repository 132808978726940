import styled from 'styled-components';

export const EventClassWrapper = styled.div`
  .warning {
    font-size: x-large;
    font-weight: bold;
    color: red;
    text-align: center;
  }
`;
