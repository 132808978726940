import React from "react"
import NewsItems from "./NewsItemsClass"
import Events from "./EventsClass"
import Services from "./Services"
import YourTeam from "./YourTeam"
import Members from "./Members"
import ContactUs from "./ContactUs"
import SectaApplicationForm from "./SectaApplicationForm"
import Login from "./Login"
import Profile from "./Profile"

const Posts = ({ data }) => {
  const slug = data.currentPage.slug
  const renderSwitch = () => {
    switch (slug){
      case 'news': return (<NewsItems />);
      case 'events': return (<Events />);
      case 'services': return (<Services />);
      case 'your-team': return (<YourTeam />);
      case 'members': return (<Members />);
      case 'contact-us': return (<ContactUs />);
      case 'secta-application-form': return (<SectaApplicationForm />);
      case 'profile': return (<Profile />);
      case 'login': return (<Login />);
      default: return (<div />);
    }
  }
  return (
    <div>
      {renderSwitch()}
    </div>
  )
}

export default Posts
