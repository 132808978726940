import React, { Component } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Metabox } from "./styles/NewsHeadingStyles"
import { format } from "date-fns"
import PostButton from "./post/PostButton"

class NewsHeading extends Component {
  state = {
    categoryName: "",
    categorySlug: "",
    authorName: "",
    authorSlug: "",
    pageSlug: "",
  }

  static propTypes = {
    news: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const {
      author,
      categories,
      slug,
    } = this.props.news
    const getAuthor = axios.get(`${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/users/${author}`)
    const getCategory = axios.get(`${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/categories/${categories[0]}`)

    Promise.all([getCategory, getAuthor]).then(res => {
      this.setState({
        categoryName: res[0].data.name,
        categorySlug: res[0].data.slug,
        authorName: res[1].data.name,
        authorSlug: res[1].data.slug,
        pageSlug: slug,
      })
    })
  }

  render() {
    const {title, excerpt, date, slug} = this.props.news
    const {authorName, categoryName} = this.state

    return (
      <div>
        <h2>{title.rendered}</h2>
        <Metabox>
          <p>Posted by <strong>{authorName}</strong> on {format(new Date(date), 'd MMM yyyy')} in <strong>{categoryName}</strong></p>
        </Metabox>
        <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
        <PostButton slug={slug} />
        <br />
      </div>
    )
  }
}

export default NewsHeading
