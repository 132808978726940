import styled from "styled-components"

export const DateButtonWrapper = styled.button`
  //position: relative;
  //padding-left: 107px;
  min-height: 77px;
  //padding-bottom: 1px;
  margin-bottom: 20px;
  
  text-decoration: none;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  padding: 5px 0 5px 0;
  color: #FFF;
  border-radius: 50%;
  background-color: #F4D35E;
  border: none;
  width: 80px;
  line-height: 1;
  cursor: pointer;
  transition: opacity .33s;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .22);
`;

export const Day = styled.span`
  text-align: center;
  display: block;
  font-size: 2.02rem;
  font-weight: 700;
`;

export const Month = styled.span`
  text-align: center;
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
`;
