import React from "react"
import { useForm } from "react-hook-form"
import { Form, Container, InputGroup, FormControl, Col } from "react-bootstrap"
import { Error } from "./styles/ContactUsStyles"

const ContactUs = () => {
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = values => {
    const phoneNo = values.phone === '' ? 'Number not supplied' : values.phone
    alert(
      `Data captured and send via email.\r\n
      First name: ${values.firstName}   Last Name: ${values.lastName}
      email: ${values.email}   Phone: ${phoneNo}
      SECTA Member: ${values.member}
      Message: ${values.message}`
    )
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Form.Row>
          <div className="col-md-12">
            <div className="card p-4">
              <div className="card-body">
                <h3 className="text-center">
                  Please fill out this form to contact us
                </h3>
                <hr />
                <Form.Row>
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="ip-firstName">
                          First Name
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="firstName"
                        ref={register({ required: "First name is required" })}
                      />
                    </InputGroup>
                    <Error>
                      {errors.firstName && errors.firstName.message}&nbsp;
                    </Error>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="ip-lastName">
                          Last Name
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="lastName"
                        ref={register({ required: "Last name is required" })}
                      />
                    </InputGroup>
                    <Error>
                      {errors.firstName && errors.lastName.message}&nbsp;
                    </Error>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-2">
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Email</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="email"
                        ref={register({
                          required: "An email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                    </InputGroup>
                    <Error>{errors.email && errors.email.message}&nbsp;</Error>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Phone</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="phone"
                        ref={register({
                          pattern: {
                            value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                            message: "Invalid phone number",
                          },
                        })}
                      />
                    </InputGroup>
                    <Error>{errors.phone && errors.phone.message}&nbsp;</Error>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <p>Are you a member of SECTA?</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type="radio"
                          id="yes"
                          name="member"
                          value="Yes"
                          ref={register}
                        />
                        <label htmlFor="yes">Yes</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type="radio"
                          id="no"
                          name="member"
                          value="No"
                          ref={register}
                          defaultChecked
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                </Form.Row>
                <Form.Row className="mt-2">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Message</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        name="message"
                        ref={register({ required: "A message is required" })}
                        as="textarea"
                      />
                    </InputGroup>
                    <Error>{errors.message && errors.message.message}&nbsp;</Error>
                </Form.Row>
                <Form.Row className="mt-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn btn-danger btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form.Row>
              </div>
            </div>
          </div>
        </Form.Row>
      </Container>
    </Form>
  )
}

export default ContactUs
