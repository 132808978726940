import React, { Component } from "react"
import { navigate } from "gatsby"
import { DateButtonWrapper, Day, Month } from "./styles/DateButtonStyles"
import { format } from "date-fns"

class DateButton extends Component {
  gotoPost(slug){
    navigate('/eventItem/', { state: {slug}})
  }
  render() {
    const dt = this.props.date
    //const dtf = dt.substr(6, 4) + '-' + dt.substr(3, 2) + '-' + dt.substr(0, 2) + 'T00:00:00'
    const dtf = dt.replace(' ', 'T')
    const dat = new Date(dtf)
    const dd = dat.getDate()
    const mm = format(new Date(dat), "MMM")
    return (
      <DateButtonWrapper onClick={this.gotoPost.bind(this, this.props.slug)}>
        <Day>{dd}</Day>
        <Month>{mm}</Month>
        {/*<Btn onClick={this.gotoPost.bind(this, this.props.slug)}>Continue Reading &raquo;</Btn>*/}
      </DateButtonWrapper>
    )
  }
}

export default DateButton
