import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Service from "./index/Service"

const Services = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      services: allWordpressWpServices(sort: {fields: acf___order_by}) {
        edges {
          node {
            wordpress_id
            title
            content
            acf {
              link
              rate
              order_by
            }
          }
        }
      }
    }
  `);
    return (
        <div className="py-5">
          <div className="container">
            <div className="row">
                {data.services.edges.map((service) =>
                  <div className="col-lg-4">
                    <Service key={service.id} data={service.node} />
                  </div>
                )}
            </div>
          </div>
        </div>
    );
};

export default Services;
