import styled from "styled-components"

export const TeamMemberWrapper = styled.div`
  text-align: center;
  color: white;
`;
export const Team_Name = styled.div`
  font-weight: bold;
  font-size: 2rem;
  @media (max-width: 1200px){
    font-size: 1.5rem;
  }
  @media (max-width: 1000px){
    font-size: 1.2rem;
  }
`;
export const Team_Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  @media (max-width: 1200px){
    font-size: 1.2rem;
  }
  @media (max-width: 1000px){
    font-size: 1.0rem;
  }
`;
export const Team_Phone = styled.div`
  font-weight: lighter;
  font-size: 1rem;
  @media (max-width: 1200px){
    font-size: 0.7rem;
  }
  @media (max-width: 1000px){
    font-size: 0.5rem;
  }
`;
export const Team_Email = styled.div`
  font-weight: lighter;
  font-size: 1rem;
  @media (max-width: 1200px){
    font-size: 0.7rem;
  }
  @media (max-width: 1000px){
    font-size: 0.5rem;
  }
`;

export const MemberImage = styled.img`
  max-height: 100px;
  
  @media (min-width: 768px) {
    max-height: 200px;
  }
`;
