import React, { Component } from "react"
import { ButtonWrapper, Btn} from "../styles/NewsHeadingStyles"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"

class PostButton extends Component {
  gotoPost(slug){
    if(!isLoggedIn()) {
      navigate("/login")
    } else {
      navigate('/newsItem/', { state: {slug}})
    }
  }
  render() {
    return (
      <ButtonWrapper>
        <Btn onClick={this.gotoPost.bind(this, this.props.slug)}>Continue Reading &raquo;</Btn>
      </ButtonWrapper>
    )
  }
}

export default PostButton
