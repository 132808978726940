import styled from "styled-components"

export const ServiceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem; 
  //padding: 0 0 0 0;
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; 
  text-align: center;
}
.card-text {
  padding: 0 10px 0 10px;
  //background-color: #cccccc;
  //border: 1px solid rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  text-align: left;
}

.card-title {
  padding: 0 10px 0 10px;
  //background-color: #cccccc;
  //border: 1px solid rgba(0, 0, 0, 0.8);
  margin-bottom: 0.75rem; 
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }
.card-link Button {
  font-size: 2rem;
  font-weight: bold;
  background-color: red;
  width: 100%;
  color: white;
  border-color: white;
  :hover {
    background-color: rgba(255, 0, 0, 0.6);
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  text-align: center;
  font-size: 2rem;
  color: white;
  font-weight: normal;
  background-color: #444444;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  //.card-header:first-child {
  //  border-radius: calc( 0.25rem - 1px ) calc( 0.25rem - 1px ) 0 0; }
  //.card-header + .list-group .list-group-item:first-child {
  //  border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc( 0.25rem - 1px ) calc( 0.25rem - 1px ); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc( 0.25rem - 1px ); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc( 0.25rem - 1px );
  border-top-right-radius: calc( 0.25rem - 1px ); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc( 0.25rem - 1px );
  border-bottom-left-radius: calc( 0.25rem - 1px ); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

  .serviceImage {
    background-size: cover;
    min-height: 200px;
  }

  .serviceText {
    /* max-width: 570px; */
    //padding: 40px 20px 20px 40px;

    @media screen and (max-width: 768px) {
      /* max-width: 100%; */
      //padding: 20px 20px 20px 20px;
    }
  }
`
