import React from "react"
// import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { Form, Container, InputGroup, FormControl, FormGroup, Col } from "react-bootstrap"
import axios from "axios"
// import { GetToken } from "../functions/getToken"
import { LoginWrapper, Error, InputGroupWrp } from "./styles/LoginStyles"
import { SubmitButton } from "./styles/SectaApplicationFormStyles"
import { navigate } from "gatsby"
// import { SetLogin } from "../functions/setLogin"
import { getToken, setUser } from "../services/auth"

const Login = ()=> {
  const { handleSubmit, register, errors } = useForm()
  // const [ loggedIn, setLoggedIn ] = useState(false)
  // let loggedIn = false
  // if(props.token === ''){
  //   SetToken()
  // }
  const onSubmit = values => {
    // handleLogin({ username: values.email, password: values.password })
    // if(isLoggedIn()){
    //   navigate('/')
    // }
    axios({
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authentication': 'Bearer ' + getToken()
      },
      url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member?filter[meta_query][0][key]=member_email&filter[meta_query][0][value]=${values.email}&filter[meta_query][1][key]=member_password&filter[meta_query][1][value]=${values.password}`,
    })
      .then(function (res) {
        // handle success
        if(res.data.length === 1){
          setUser({
            id: res.data[0].id,
            username: res.data[0].acf.member_email,
            name: res.data[0].acf.member_name
          })
          navigate('/')
        } else {
          alert('Email/password combination couldn\'t be found')
        }
      })
      .catch(function (error) {
        // handle error
        console.log("error:", error);
      })
  }
  // if(loggedIn) {
  //   SetLogin(true)
  //   navigate('/')
  // }
  return (
    <LoginWrapper onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-email">Email</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="email"
                ref={register({ required: "Your email is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.email && errors.email.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-password">Password</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="password"
                type="password"
                ref={register({ required: "Password is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.password && errors.password.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs={10} />
          <Col>
            <FormGroup>
              <SubmitButton name="submit" id="submit" type="submit" value="Submit" variant="filled" />
            </FormGroup>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
      </Container>
    </LoginWrapper>
  )
}
// const mapStateToProps = ({ token }) => {
//   return { token }
// }
// export default connect(mapStateToProps)(Login)
export default Login
