import React from "react"
import { ServiceWrapper } from "./styles/ServiceStyles"
import { Card, Button } from "react-bootstrap"

const Service = (data) => {
  const { title, acf, content, wordpress_id } = data.data
  const { link, rate } = acf
  return (
    <ServiceWrapper key={wordpress_id} className="">
      <Card className="text-center">
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <Card.Title>{rate}</Card.Title>
          <Card.Text dangerouslySetInnerHTML={{ __html: content }} />
        </Card.Body>
        <Card.Body>
          <Card.Link href={link}>
            <Button className="btn btn-danger btn-block mt-2">Join Now</Button>
          </Card.Link>
        </Card.Body>
        {title !== 'SECTA Membership' ? (
          <Card.Footer className="text-muted">1 Year Plan</Card.Footer>
        ) : (
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>
        )}
      </Card>
    </ServiceWrapper>
  )
}

export default Service
