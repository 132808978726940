import React, { Component } from "react"
import axios from 'axios'
import NewsHeading from "./NewsHeading"

class NewsItemsClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      newsItems: []
    }
  }

  componentDidMount() {
      axios({
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/posts/`,
      })
        .then(res => this.setState({
          newsItems: res.data,
          isLoaded: true
        }))
        .catch(err => console.log(err))
  }
  render() {
    const {newsItems} = this.state;
    return (
      <div>
        {newsItems !== null ? newsItems.map(newsItem =>
          <NewsHeading key={newsItem.id} news={newsItem} />
        ) : ''}
      </div>
    )
  }
}

export default NewsItemsClass
