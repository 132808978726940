import React from 'react';
import { MemberImage, TeamMemberWrapper, Team_Name, Team_Title, Team_Phone, Team_Email } from "./styles/TeamMemberStyles"

const TeamMember = ({ image, name, title, phone, email}) => {
    return (
        <TeamMemberWrapper>
          <MemberImage src={image} className="mb-2" />
          <Team_Name>{name}</Team_Name>
          <Team_Title>{title}</Team_Title>
          <Team_Phone>{phone}</Team_Phone>
          <Team_Email>{email}</Team_Email>
        </TeamMemberWrapper>
    );
};

export default TeamMember;
