import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import {BannerText} from "./styles/PageHeroStyles"

const PageHero = ({ img, title, subTitle }) => (
  <div className="container-fluid">
    <div className="col" style={{ padding: 0 }}>
      <Img fluid={img} />
      <BannerText>
        <h1>{title}</h1>
        <article>{subTitle}</article>
      </BannerText>
    </div>
  </div>
)

PageHero.propTypes = {
  img: PropTypes.object,
}

export default PageHero
