/* eslint-disable react/no-danger */
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import PageSidebar from "../components/page/PageSidebar"
import PageHero from "../components/PageHero"
import Posts from "../components/Posts"

const PageContent = styled.div`
  margin: 20px 0 0 0;
`;
const PageTemplate = ({ data }) => (
  <Layout>
    {data.currentPage.featured_media ? (
      <PageHero
        img={data.currentPage.featured_media.localFile.childImageSharp.fluid}
        title={
          data.currentPage.title === 'Login' ||
          data.currentPage.title === 'Cookie Policy' ||
          data.currentPage.title === 'Terms and Conditions' ||
          data.currentPage.title === 'Privacy Policy' ||
          data.currentPage.title === 'Profile' ||
          data.currentPage.title === 'Rules and Codes of Practise' ? ''
          // data.currentPage.title === 'Newsletters'
            : data.currentPage.title
        }
        subTitle={data.currentPage.acf.page_banner_subtitle}
      />
    ) : null}
    <div className="container">
      <div className="row" style={{ marginBottom: "40px" }}>
        <PageSidebar
          parentChildren={data.parentChildren}
          currentPage={data.currentPage}
          parent={data.parent}
        >
          {data.children}
        </PageSidebar>
        {data.currentPage.slug === 'services' ||
        data.currentPage.slug === 'your-team' ||
        data.currentPage.slug === 'secta-application-form' ? (
          <PageContent className="col-lg-12">
            <div dangerouslySetInnerHTML={{ __html: data.currentPage.content }} />
            <Posts data={data} />
          </PageContent>
        ) : (
          <PageContent className="col-lg-9">
            <div dangerouslySetInnerHTML={{ __html: data.currentPage.content }} />
            <Posts data={data} />
          </PageContent>
        )}
      </div>
    </div>
  </Layout>
)

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $parent: Int!, $wpId: Int!) {
    currentPage: wordpressPage(id: { eq: $id }) {
      title
      acf {
        page_banner_subtitle
      }
      slug
      content
      wordpress_parent
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    parentChildren: allWordpressPage(
      filter: { wordpress_parent: { eq: $parent } }
    ) {
      edges {
        node {
          id
          title
          link
        }
      }
    }
    children: allWordpressPage(filter: { wordpress_parent: { eq: $wpId } }) {
      edges {
        node {
          id
          title
          link
        }
      }
    }
    parent: wordpressPage(wordpress_id: { eq: $parent }) {
      title
      link
    }
  }
`
