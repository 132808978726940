import React from 'react';
import { YourTeamWrapper } from "./styles/YourTeamStyles"
import TeamMember from "./index/TeamMember"

import SueJewel from "../images/team/person-sue-jewell.png"
import KathyEagleton from "../images/team/person-kathy-eagleton.png"
import DiWare from "../images/team/person-di-ware2.png"
import LindaBrown from "../images/team/person-linda-brown.jpg"
import TrishBrockMorgan from "../images/team/person-trish.jpg"
import AngelaWaine from "../images/team/person-angela-waine.png"
import KathBond from "../images/team/person-kath-bond.png"
import KimSpencer from "../images/team/person-kim-spencer.png"
// import Blank from "../images/team/blank-profile.png"
import StephenMaitland from "../images/team/person-Stephen-Maitland.jpg"

const YourTeam = () => {
    return (
        <YourTeamWrapper>
          <div className="container">
            <h1>SECTA Committee</h1>
            <hr/>
            <div className="row">
              <div className="col-md-6 mb-5">
                <TeamMember image={SueJewel} name="Sue Jewel" title="Chair" phone="01579 320880" email="chairman@secta.org.uk" />
              </div>
              <div className="col-md-6 mb-5">
                <TeamMember image={KimSpencer} name="Kim Spencer" title="Vice Chair" phone="01503 240685" email="holidays@wringworthy.co.uk" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-5">
                <TeamMember image={DiWare} name="Di Ware" title="Secretary" phone="01579 320650" email="bad.ware@btinternet.com" />
              </div>
              <div className="col-md-3 mb-5">
                <TeamMember image={LindaBrown} name="Linda Brown" title="Treasurer/Quality in Cornwall Enquiries" phone="07929 265933" email="treasurer@secta.org.uk" />
              </div>
              <div className="col-md-3 mb-5">
                <TeamMember image={TrishBrockMorgan} name="Trish Brock-Morgan" title="Liaison, Marketing & Events" phone="01579 343543" email="trish@trecarnehouse.co.uk" />
              </div>
              <div className="col-md-3 mb-5">
                <TeamMember image={AngelaWaine} name="Angela Waine" title="Quality in Cornwall & eco Cornwall Inspector" phone="" email="inspection@secta.org.uk" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-5">
                <TeamMember image={KathyEagleton} name="Kathy Eagleton" title="South East Cornwall in Bloom" phone="" email="" />
              </div>
              <div className="col-md-4 mb-5">
                <TeamMember image={KathBond} name="Kath Bond" title="Training" phone="07500 975622" email="kath.bond@ccbtraining.co.uk" />
              </div>
              <div className="col-md-4 mb-5">
                <TeamMember image={StephenMaitland} name="Stephen Maitland" title="IT Consultant" phone="07795 363191" email="stephen@moorsolutions.co.uk" />
              </div>
            </div>
          </div>
        </YourTeamWrapper>
    );
};

export default YourTeam;
