import React, { useState, useEffect } from "react"
import { Form, Container, InputGroup, FormControl, FormGroup, Col } from "react-bootstrap"
import { LogoImg, MailImg, MobileImg, SectaApplicationFormWrapper, SubmitButton, InputGroupWrp, Error } from "./styles/SectaApplicationFormStyles"
import axios from 'axios'
import Logo from "../images/SECTA-logo-wave.png"
import Mail from "../images/mail.png"
import Mobile from "../images/Mobile.png"
import { useForm } from "react-hook-form"
// import { navigate } from "gatsby"
import { getToken, GetUsersId, setUser } from "../services/auth"
// import { getUserProfile } from "../services/user"
// import { useFormInput } from "../hooks"

const Profile = () => {
  const { handleSubmit, register, errors } = useForm()
  // const [ profile, setProfile ] = useState({})
  const [ id, available ] = GetUsersId()
  // const email = useFormInput(profile && profile.email)
  const [ loaded, setLoaded ] = useState('false')
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ passwordConfirm, setPasswordConfirm ] = useState('')
  const [ name, setName ] = useState('')
  const [ business_name, setBusiness_name ] = useState('')
  const [ address, setAddress ] = useState('')
  const [ town, setTown ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ mobile, setMobile ] = useState('')
  const [ website, setWebsite ] = useState('')
  const [ alt_address, setAlt_address ] = useState('')
  const [ alt_town, setAlt_town ] = useState('')
  const [ alt_phone, setAlt_phone ] = useState('')
  const [ alt_mobile, setAlt_mobile ] = useState('')
  const [ alt_email, setAlt_email ] = useState('')

  const handleNameInput = e => {
    setName(e.target.value)
  }
  const handlePasswordInput = e => {
    setPassword(e.target.value)
  }
  const handlePasswordConfirmInput = e => {
    setPasswordConfirm(e.target.value)
  }
  const handleBusinessNameInput = e => {
    setBusiness_name(e.target.value)
  }
  const handleAddressInput = e => {
    setAddress(e.target.value)
  }
  const handleTownInput = e => {
    setTown(e.target.value)
  }
  const handlePhoneInput = e => {
    setPhone(e.target.value)
  }
  const handleMobileInput = e => {
    setMobile(e.target.value)
  }
  const handleWebsiteInput = e => {
    setWebsite(e.target.value)
  }
  const handleAltAddressInput = e => {
    setAlt_address(e.target.value)
  }
  const handleAltTownInput = e => {
    setAlt_town(e.target.value)
  }
  const handleAltPhoneInput = e => {
    setAlt_phone(e.target.value)
  }
  const handleAltMobileInput = e => {
    setAlt_mobile(e.target.value)
  }
  const handleAltEmailInput = e => {
    setAlt_email(e.target.value)
  }

  useEffect( () => {
    if(available === 'true' && loaded === 'false') {
      axios({
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        // url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member?filter[meta_key]=member_email&filter[meta_value]=${email}`,
        url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member/${id}`,
      })
        .then(res => {
          setEmail(res.data.acf.member_email)
          setPassword(res.data.acf.member_password)
          setPasswordConfirm(res.data.acf.member_password)
          setName(res.data.acf.member_name)
          setBusiness_name(res.data.acf.member_business_name)
          setAddress(res.data.acf.member_address)
          setTown(res.data.acf.member_town)
          setPhone(res.data.acf.member_phone)
          setMobile(res.data.acf.member_mobile)
          setWebsite(res.data.acf.member_website)
          setAlt_address(res.data.acf.member_alt_address)
          setAlt_town(res.data.acf.member_alt_town)
          setAlt_phone(res.data.acf.member_alt_phone)
          setAlt_mobile(res.data.acf.member_alt_mobile)
          setAlt_email(res.data.acf.member_alt_email)
        })
        .catch(err => console.log(err))
      setLoaded('true')
    }
  }, [available, loaded, id])
  const onSubmit = values => {
    if (values.password !== values.passwordConfirm) {
      errors["passwordConfirm"] = "Passwords don't match.";
      alert('Passwords do not match');
      return;
    }
    console.log("password:", values.password)
    axios({
      method: 'put',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authentication': 'Bearer ' + getToken()
      },
      // url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member?filter[meta_key]=member_email&filter[meta_value]=${email}`,
      url: `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/secta_member/${id}`,
      data: {
        status: `publish`,
        fields: {
          member_password: `${values.password}`,
          member_name: `${values.name}`,
          member_business_name: `${values.business_name}`,
          member_address: `${values.addr}`,
          member_town: `${values.town}`,
          member_phone: `${values.phone}`,
          member_mobile: `${values.mobile}`,
          member_website: `${values.website}`,
          member_alt_address: `${values.altAddr}`,
          member_alt_town: `${values.altTown}`,
          member_alt_phone: `${values.altPhone}`,
          member_alt_mobile: `${values.altMobile}`,
          member_alt_email: `${values.altEmail}`
        }
      }
    })
    setUser({
      id: id,
      username: email,
      name: values.name
    })
    // .then(res => console.log('Success'))
    // .catch(err => console.log('Failed: ', err))
    alert(`Your profile has been successfully updated`)
    // navigate('/')
    // })
  }
  return (
    <SectaApplicationFormWrapper onSubmit={handleSubmit(onSubmit)}>
    {/*<SectaApplicationFormWrapper onSubmit={onSubmit}>*/}
      <Container>
        {/* Email */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-email">Email</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="email"
                value={email}
                disabled={true} />
            </InputGroupWrp>
            <Error>{errors.email && errors.email.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Password */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-password">Password</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="password"
                type="password"
                value={password}
                ref={register()}
                onChange={handlePasswordInput}
              />
            </InputGroupWrp>
            <Error>{errors.password && errors.password.message}&nbsp;</Error>
          </Col>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-passwordConfirm">Confirm Password</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="passwordConfirm"
                type="password"
                value={passwordConfirm}
                ref={register()}
                onChange={handlePasswordConfirmInput}
              />
            </InputGroupWrp>
            <Error>{errors.passwordConfirm && errors.passwordConfirm.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Name */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-name">Your Name(s)</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="name"
                value={name}
                onChange={handleNameInput}
                ref={register({ required: "Name is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.name && errors.name.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Business Name */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-business_name">Business Name</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="business_name"
                value={business_name}
                onChange={handleBusinessNameInput}
                ref={register()}
              />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Address */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-addr">Address</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="addr" ref={register()}
                value={address}
                onChange={handleAddressInput}
                // ref={register({ required: "Address line is required" })}
              />
            </InputGroupWrp>
            <Error>{errors.addr && errors.addr.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Town */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altTown">Town</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="town" ref={register()}
                value={town}
                onChange={handleTownInput}
              />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Phone/Mobile */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-phone">Phone</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="phone"
                value={phone}
                onChange={handlePhoneInput}
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid phone number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.phone && errors.phone.message}&nbsp;</Error>
          </Col>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-mobile">Mobile</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="mobile"
                value={mobile}
                onChange={handleMobileInput}
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid mobile number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.mobile && errors.mobile.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Website */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-website">Website</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="website"
                value={website}
                onChange={handleWebsiteInput}
                ref={register({
                  pattern: {
                    value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/i,
                    message: "Invalid website",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.website && errors.website.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        <p>The above details are on the website for members of the public to see. Your alternative address, phone/mobile number or email address for the SECTA administrator and news service to contact you, are below.</p>
        {/* Alt Address */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altAddr">Address</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altAddr" ref={register()}
                value={alt_address}
                onChange={handleAltAddressInput}
              />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Alt Town */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altTown">Town</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altTown" ref={register()}
                value={alt_town}
                onChange={handleAltTownInput}
              />
            </InputGroupWrp>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Alt Phone/Alt Mobile */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altPhone">Phone</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altPhone"
                value={alt_phone}
                onChange={handleAltPhoneInput}
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid phone number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.altPhone && errors.altPhone.message}&nbsp;</Error>
          </Col>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altMobile">Mobile</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altMobile"
                value={alt_mobile}
                onChange={handleAltMobileInput}
                ref={register({
                  pattern: {
                    value: /^[+]*[(]?[0-9]{1,4}[)]?[\s0-9]*$/i,
                    message: "Invalid phone number",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.altMobile && errors.altMobile.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Alt Email */}
        <Form.Row>
          <Col>
            <InputGroupWrp>
              <InputGroup.Prepend>
                <InputGroup.Text id="ip-altEmail">Email</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                name="altEmail"
                value={alt_email}
                onChange={handleAltEmailInput}
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
            </InputGroupWrp>
            <Error>{errors.altEmail && errors.altEmail.message}&nbsp;</Error>
          </Col>
        </Form.Row>
        {/* Submit */}
        <Form.Row>
          <Col xs={10}></Col>
          <Col>
            <FormGroup>
              <SubmitButton name="submit" id="submit" type="submit" value="Submit" variant="filled" />
            </FormGroup>
            <Error>&nbsp;</Error>
          </Col>
        </Form.Row>
        <p>I agree to comply with all statutory obligations, and to abide by
          the <a href="/rules-and-codes-of-practise">Rules and Codes of Practice</a> of the South East Cornwall Tourism Association.</p>
        {/* Logo */}
        <Form.Row>
          <Col xs={6}>
            <LogoImg src={Logo} alt="logo"/>
          </Col>
          <Col>
            <p>Member contact: Trish Brock-Morgan<br/>
              <MailImg src={Mail} alt="mail" /> trish@trecarnehouse.co.uk<br/>
              <MobileImg src={Mobile} alt="mobile" /> 07950 262682</p>
          </Col>
        </Form.Row>
      </Container>
    </SectaApplicationFormWrapper>
  )
}
// const mapStateToProps = ({ token }) => {
//   return { token }
// }
// export default connect(mapStateToProps)(SectaApplicationForm)
export default Profile
